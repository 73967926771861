import React, { useRef, useState, useEffect } from "react";
import { Typography, MenuItem, Avatar, Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import MenuPopover from "../MenuPopover";
import data from "../../utilities/constants";
import { useAuth } from "../../hooks";
import { emitEvent } from "../../socket/socket";
import { updateOperatorStatus } from "../../slices/operaters";
//import { nanoid } from "nanoid";
//import { commentBlockIcon } from "../../assets";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
export default function AccountPopover() {
  const dispatch = useDispatch();
  const { logout } = useAuth();
  const history = useHistory();
  const anchorRef = useRef(null);
  const { t } = useTranslation();
  const [openAccount, setOpenAccount] = useState(null);
  //const [openStatus, setOpenStatus] = useState(null);
  const handleOpenAccount = (event) => {
    setOpenAccount(event.currentTarget);
  };
  const { user } = useSelector((state) => state.user);
  // useEffect(() => {
  //   if (user) {
  //     if (user?.availability?.status === "online") {
  //       setOpenStatus(t("Set yourself as away").replace(" ", "\xa0"));
  //     } else {
  //       setOpenStatus(t("Set yourself as online").replace(" ", "\xa0"));
  //     }
  //   }
  // }, [user]);

  const handleClose = () => {
    setOpenAccount(null);
  };

  const handleLogout = async () => {
    try {
      emitEvent("LEAVE_ROOM", {
        websiteId: user?.websiteID,
        operatorId: user._id,
      });
      dispatch(
        updateOperatorStatus({
          availability: {
            status: "offline",
            since: new Date().toISOString(),
          },
          websiteId: user?.websiteID,
        })
      );
      logout();
      localStorage.removeItem("showModal");
      localStorage.removeItem("redirectModal");
      dispatch({ type: "LOGOUT" });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const handleInvalidToken = (e) => {
      if (e.key === "token" && e.oldValue && !e.newValue) {
        logout();
        dispatch({ type: "LOGOUT" });
      }
    };
    window.addEventListener("storage", handleInvalidToken);

    return function cleanup() {
      window.removeEventListener("storage", handleInvalidToken);
    };
  }, [dispatch]);
  return (
    <React.Fragment>
      <Grid
        container
        ref={anchorRef}
        onClick={handleOpenAccount}
        sx={{
          // maxWidth: "50px",
          // margin: "0 auto",
          // display: "flex",
          // justifyContent: "center",
          // p: 1,
          // width: 194,
          //  backgroundColor: "#FFF",
          //  border: "1px solid #ebebeb",
          // borderRadius: "9px",
          cursor: "pointer",
          // borderTopLeftRadius: !openAccount ? "9px" : "0px",
          // borderTopRightRadius: !openAccount ? "9px" : "0px",
        }}
      >
        <Grid item xs={12}>
          <Avatar sx={{ width: 30, height: 30 }} src={user?.avatar} />
        </Grid>
        {/* <Grid item xs={9}>
          <Typography className="username-style-account-popover">
            {user?.firstName} {user?.lastName}
          </Typography>
          <Typography className="team-style-account-popover" variant="body2">
            {user?.role?.code}
          </Typography>
        </Grid> */}
      </Grid>
      <MenuPopover
        open={Boolean(openAccount)}
        anchorEl={openAccount}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 0,
          ml: 0.75,
          borderRadius: "9px",
          borderBottomLeftRadius: !openAccount ? "9px" : "0px",
          borderBottomRightRadius: !openAccount ? "9px" : "0px",
          "& .MuiMenuItem-root": {
            typography: "body2",
            boxShadow: 0,
          },
        }}
      >
        {/* <MenuItem
          key={nanoid(5)}
          className="account-popover-section"
          onClick={() => {
            if (openStatus === "Set your self as away") {
              setOpenStatus("Set your self as online");
              emitEvent("AWAY_FROM_CHAT", {
                operatorId: user?._id,
                websiteId: user?.websiteID,
              });
              dispatch(
                updateOperatorStatus({
                  availability: {
                    status: "away",
                    since: new Date().toISOString(),
                  },
                  websiteId: user?.websiteID,
                })
              );
            } else {
              setOpenStatus("Set your self as away");
              emitEvent("CONNECT", {
                operatorId: user?._id,
                websiteId: user?.websiteID,
              });
              dispatch(
                updateOperatorStatus({
                  availability: {
                    status: "online",
                    since: new Date().toISOString(),
                  },
                  websiteId: user?.websiteID,
                })
              );
            }
          }}
        >
          <LazyLoadImage src={commentBlockIcon} alt={"commentBlockIcon"} />
          <div className="account-popover-section-text">{t(openStatus)}</div>
        </MenuItem> */}

        {data.accountPopoverConfig.map((item) => (
          <MenuItem
            key={item.id}
            className="account-popover-section"
            onClick={() => {
              item.title === "Logout"
                ? handleLogout()
                : history.push(item.onClick);

              // if (item.title === "Set Yourself As Away") {
              //   item.title = "Set Yourself As Online";
              // } else if (item.title === "Set Yourself As Online") {
              //   item.title = "Set Yourself As Away";
              // }
            }}
          >
            <LazyLoadImage src={item.icon} alt={item.alt} />
            <div
              className="account-popover-section-text"
              style={{
                color: item.alt === "log-out" ? "#ED3863" : "",
              }}
            >
              {t(item.title)}
            </div>
          </MenuItem>
        ))}
      </MenuPopover>
    </React.Fragment>
  );
}
